import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FeedbackModal } from "./feedbackModal";
import { SmileyButtons } from "./smileyButtons";
import { CircleCheck } from "../../../../../assets";
import { useSmileyButtons } from "../../hooks/useSmileyButtons/index.js";
import useAPI from "../../../../../hooks/useAPI";
import { UserContext } from "../../../../../hooks/useWhoAmI";

export const SuccessPage = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackSection, setShowFeedbackSection] = useState(true);
  const { currentSmiley, setCurrentSmiley } = useSmileyButtons();
  const history = useHistory();
  const { me } = useContext(UserContext);
  // eslint-disable-next-line
  const [feedbackResult, loading, error, refreshFeedback] = useAPI({ me, path: "v1/feedback/" });

  useEffect(() => {
    if (!loading) {
      setShowFeedbackSection(feedbackResult.length === 0);
    }
  }, [feedbackResult, loading]);

  useEffect(() => {
    setTimeout(() => {
      setShowFeedbackModal(true);
    }, 1000);
  }, []);

  const removeSmileyAndHideModal = () => {
    setCurrentSmiley(null);
    setShowFeedbackModal(false);
  };

  const setSmileyAndOpenModal = (id) => {
    setCurrentSmiley(id);
    setShowFeedbackModal(true);
  };

  const viewSubmittedInfo = () => {
    history.push("/dashboard");
  };

  return (
    <div className="w-full bg-float-purple-7.5 pl-[90px]">
      <div className="min-h-screen flex flex-col m-auto items-center text-float-purple-1 text-center w-[362px] gap-6 pt-[150px]">
        <img className="w-[88px] mb-3" src={CircleCheck} alt="Success icon" />
        <h2>Success! Your financial info has been submitted</h2>
        <p>
          Thank you, we will provide you with a credit offer via email within a
          few days. If we have any questions about your application we will get
          back to you to schedule a call.
        </p>
        <span className="bg-float-grey-10 w-3/4 h-px my-2"></span>
        {showFeedbackSection && (
          <>
            <div>
              <h3>How was your experience so far?</h3>
              <div className="flex gap-3 justify-center mt-4">
                <SmileyButtons onClick={setSmileyAndOpenModal} />
              </div>
            </div>
            <span className="bg-float-grey-10 w-3/4 h-px my-2"></span>
          </>
        )}
        <div className="flex flex-col gap-2 w-full mt-4 pb-10">
          <button
            className="bg-transparent text-float-purple-2 font-bold"
            onClick={viewSubmittedInfo}
          >
            View submitted information
          </button>
          <button className="font-bold">Logout</button>
        </div>
      </div>
      {showFeedbackSection && (
        <FeedbackModal
          setCurrentSmiley={setCurrentSmiley}
          currentSmiley={currentSmiley}
          hide={removeSmileyAndHideModal}
          visible={showFeedbackModal}
          onSubmitSuccess={refreshFeedback}
        />
      )}
    </div>
  );
};
