import { saveOrgNr } from "../../common/localstorage";
import { useContext, useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { CountryContext } from "../../hooks/useCountry";
import { Icon } from "@iconify/react";
import { useHandleClickOutside } from "../../hooks/useHandleClickOutside";

const CompanyRow = ({ company, hide, setCountry, history }) => {
  return (
    <div
      className="cursor-pointer p-6 border-t"
      onClick={() => {
        hide();
        saveOrgNr(company.organisation_number);
        setCountry(company.country);
        history.go(0);
      }}
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-3 items-center">
          <div className="bg-float-purple-5 rounded-full w-10 h-10 flex items-center justify-center">
            <Icon
              icon="majesticons:suitcase-2-line"
              className="text-float-purple-2"
              width={24}
            />
          </div>
          <p className="text-float-purple-2 font-bold">{company.name}</p>
        </div>
        <Icon
          icon="ic:round-keyboard-arrow-right"
          className="text-float-grey-25"
          height={24}
        />
      </div>
    </div>
  );
};
export const ModalContent = ({ title, hide, history, setCountry, me }) => {
  return (
    <div className={`relative p-4 h-3/4 md:h-auto w-fit max-w-4xl`}>
      <div className="relative bg-white rounded-xl shadow-xl .box-shadow">
        <div className="flex justify-between items-start p-6 relative">
          <h3 className="font-bold">{title}</h3>
          <div
            className="text-gray-400 bg-transparent rounded-lg text-sm items-center cursor-pointer absolute right-5"
            onClick={hide}
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        <div className="flex flex-col w-[500px]">
          {me.company.map((company) => {
            return (
              <CompanyRow
                key={company.organisation_number}
                company={company}
                hide={hide}
                history={history}
                setCountry={setCountry}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
export const SelectCompanyModal = ({ hide, visible, title, me }) => {
  const { setCountry } = useContext(CountryContext);
  const history = useHistory();
  const ref = useRef();

  useHandleClickOutside(ref, () => hide());
  const closeFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        hide();
      }
    },
    [hide]
  );

  useEffect(() => {
    document.addEventListener("keydown", closeFunction, false);

    return () => {
      document.removeEventListener("keydown", closeFunction, false);
    };
  }, [closeFunction]);

  return (
    <div
      id="modal"
      aria-hidden="true"
      className={`${visible ? "visible" : "hidden"}
      overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen md:h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div className={`relative p-4 h-3/4 md:h-auto w-fit max-w-4xl`} ref={ref}>
        <div className="relative bg-white rounded-xl shadow-xl .box-shadow">
          <div className="flex justify-between items-start p-6 relative">
            <h3 className="font-bold">{title}</h3>
            <div
              className="text-gray-400 bg-transparent rounded-lg text-sm items-center cursor-pointer absolute right-5"
              onClick={hide}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-col w-[500px]">
            {me.company.map((company) => {
              return (
                <CompanyRow
                  key={company.organisation_number}
                  company={company}
                  hide={hide}
                  history={history}
                  setCountry={setCountry}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
