import { useFormik } from "formik";
import { useEffect, useState, useContext } from "react";
import {
  getWhoAmI,
  login,
  patchUser,
  registerUser,
} from "../../../../../../../api";
import { useGetErrorMessage } from "../../../../../../../utils";
import { useGetValidationSchema } from "../useGetValidationSchema/useGetValidationSchema";
import { UserContext } from "../../../../../../../hooks/useWhoAmI";

export const useYourDetailsForm = ({
  nextPage,
  email,
  captcha,
  isActiveUser,
}) => {
  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState({});
  const { errorMessages } = useGetErrorMessage();
  const { validationSchema } = useGetValidationSchema({ isActiveUser });
  const { login: loginContext } = useContext(UserContext);

  useEffect(() => {
    const getMe = async () => {
      try {
        const me = await getWhoAmI();
        setMe(me);
      } catch (e) {
        console.log(e);
      }
    };

    if (isActiveUser) {
      getMe();
    }
  }, [isActiveUser]);

  const onSubmit = async (data) => {
    if (!captcha && !isActiveUser) {
      setSubmitError({ other: "Must include captcha" });
      return;
    }

    setLoading(true);
    setSubmitError({});

    if (me) {
      try {
        patchUser({
          email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          job_title: data.job_title,
        });
        nextPage();
      } catch (e) {
        const error = errorMessages(e.response.data);
        setSubmitError(error);
        // Reset captcha in case of error since token has already been used.
        window.grecaptcha.reset();
      } finally {
        setLoading(false);
      }
    } else {
      try {
        await registerUser({
          ...data,
          email,
          captcha,
        });
        const code = await login({
          email,
          password: data.password1,
        });
        loginContext(code);
        nextPage();
      } catch (e) {
        const error = errorMessages(e.response.data);
        setSubmitError(error);
        // Reset captcha in case of error since token has already been used.
        window.grecaptcha.reset();
      } finally {
        setLoading(false);
      }
    }
  };

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
    initialValues: {
      first_name: me?.first_name || "",
      last_name: me?.last_name || "",
      phone_number: me?.phone_number || "",
      job_title: me?.job_title,
      password1: "",
      password2: "",
    },
  });

  return {
    formik,
    loading,
    submitError,
  };
};
