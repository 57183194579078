import { withRouter } from "react-router";
import { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import Loading from "../../../components/loading";
import { UserContext } from "../../../hooks/useWhoAmI";
import {
  AccountingModals,
  BankModals,
  OnboardInfo,
  SubscriptionAccordion,
  SubscriptionModals,
  SubmitModal,
} from "./components";
import { useFileUploader } from "../../../hooks/useFileUploader";
import {
  AccountingAccordion,
  SelectCompanyModal,
  Toast,
  TransactionAccordion,
  WelcomeModal,
} from "../../../components";
import { useConnectBankLater, useExpandedAccordion } from "./hooks/";
import { ToastType } from "../../../types";
import {
  setExpandedAccordion,
  StorageKeys,
} from "../../../common/localstorage";
import { Icon } from "@iconify/react";
import { ShowCompanyModalContext } from "../../../hooks/useShowCompanyModal";
import useAPI from "../../../hooks/useAPI";

const Onboard = ({ location, edit = false }) => {
  const { connectLater } = useConnectBankLater();
  const { me, updateWhoAmI } = useContext(UserContext);
  const [resultApplication, loadingApplication] = useAPI({
    me,
    path: "v1/application/",
    resultDefaultState: null,
  });
  const { isCompanyModalVisible, setShowCompanyModal } = useContext(
    ShowCompanyModalContext
  );
  const {
    files,
    setFile,
    uploadedFilesLength,
    onHandleSubmit,
    onHandleRemove,
    uploadComplete,
  } = useFileUploader();
  const { accountingExpanded, subscriptionExpanded, transactionExpanded } =
    useExpandedAccordion();
  const search = new URLSearchParams(location.search);

  const [loading, setIsLoading] = useState(false);
  const [text, setText] = useState("");
  const [fileUploadError, setFileUploadError] = useState("");
  const [step, setStep] = useState(0);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(
    me.show_onboarding_guide
  );
  const history = useHistory();

  const uploadFilesAndComment = (isAccountingSoftwareSection) => {
    return async ({ e, fileEndpoint, commentEndpoint }) => {
      try {
        setIsLoading(true);
        const uploadedFiles = await onHandleSubmit(
          e,
          fileEndpoint,
          setFileUploadError
        );
        if (uploadedFiles) {
          const fileIds = uploadedFiles.map((file) => file.id);

          if (text !== "") {
            await commentEndpoint({
              text,
              files: fileIds,
              company: me.selected_company.id,
            });
          }

          setExpandedAccordion(
            isAccountingSoftwareSection
              ? StorageKeys.ACCOUNTING
              : StorageKeys.SUBSCRIPTION
          );
          updateWhoAmI();

          hide();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
  };

  let connect = search.get("connect");
  const gocardless_reference = search.get("gocardless_reference");
  const gocardless_error = search.get("error");

  const notifyAccounting = useCallback(() => {
    if (connect) {
      Toast({
        type: ToastType.SUCCESS,
        title: "Yes, success!",
        text: "Thank you - we successfully synched your financial statements",
      });
      setExpandedAccordion(StorageKeys.ACCOUNTING);
      updateWhoAmI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connect, history]);

  useEffect(() => {
    if (gocardless_reference) {
      setStep(13);
    }
  }, [gocardless_reference]);

  useEffect(() => {
    notifyAccounting();
  }, [notifyAccounting]);

  const isDone =
    me.has_accounting &&
    me.has_subscription &&
    (me.has_transactions || me.selected_company.bank_connect_later);

  const shouldRenderSubmitApplication = () => {
    if (Array.isArray(resultApplication) && resultApplication.length === 0) {
      return true;
    }
    if (resultApplication?.status === "expired") {
      return true;
    }
    return false;
  };


  if (!me || loadingApplication) {
    return <Loading />;
  }

  const hide = () => {
    setStep(0);
    setFile([]);
    setText("");
    setFileUploadError("");
  };

  const SyncCallback = () => {
    Toast({
      type: ToastType.SUCCESS,
      title: "Yes, success",
      text: "Successfully connected bank",
    });

    setExpandedAccordion(StorageKeys.TRANSACTION);
    updateWhoAmI();
  };

  const SubscriptionCallback = () => {
    setExpandedAccordion(StorageKeys.ACCOUNTING);
    updateWhoAmI();
  };

  return (
    <>
      <div className="bg-float-purple-6 w-screen min-h-screen">
        <div className=" bg-float-purple-6 grid lg:grid-cols-[minmax(500px,1fr)_minmax(350px,350px)] pt-[130px] gap-5 lg:gap-8 xl:gap-12 pr-24 pl-[150px] max-w-screen-2xl">
          <div className="grid grid-rows-[minmax(80px,100px)_minmax(100px,1fr)] gap-7">
            <div className="w-full">
              {resultApplication?.status === "pending" ? (
                <>
                  <h2 className="pb-2 text-lg md:text-2xl">
                    Submitted connections & data
                  </h2>
                  <div className="flex gap-4">
                    <div className="w-fit flex items-center">
                      <Icon
                        icon="ph:clock-clockwise"
                        className="text-float-purple-2"
                        height={32}
                      />
                    </div>
                    <p className="text-sm md:text-base">
                      We are now going through your provided financial
                      information and will get back to you within a few days
                      with an accurate credit offer.
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <h2 className="pb-2 capitalize">
                    Welcome {me.first_name.split(" ")[0].toLowerCase()}!
                  </h2>
                  <p>
                    We are happy to have you onboard. Connecting your financial
                    data will let us assess your credit rating and provide you
                    with an accurate offer.
                    <span
                      className="ml-1 text-float-purple-2 cursor-pointer"
                      onClick={() => setShowWelcomeModal(true)}
                    >
                      View the introduction again.
                    </span>
                  </p>
                </>
              )}
            </div>
            <div className="flex flex-col space-y-4 mt-8 sm:mt-0">
              <div className="w-full">
                <AccountingAccordion
                  checked={me.has_accounting}
                  onUploadButtonClick={() => setStep(1)}
                  onSuportedAccountingClick={() => setStep(2)}
                  onDataClick={() => setStep(3)}
                  uploadComplete={uploadComplete}
                  open={accountingExpanded}
                />
              </div>
              <div className="w-full">
                <SubscriptionAccordion
                  checked={me.has_subscription}
                  onUploadButtonClick={() => setStep(4)}
                  onSupportedSubscriptionClick={() => setStep(5)}
                  onDataClick={() => setStep(6)}
                  uploadComplete={uploadComplete}
                  open={subscriptionExpanded}
                  onConnect={() => setStep(10)}
                />
              </div>
              <div className="w-full">
                <TransactionAccordion
                  checked={me.has_transactions}
                  connectLater={me.selected_company.bank_connect_later}
                  bankCallBack={SyncCallback}
                  onSupportedBanksClick={() => setStep(7)}
                  onInfoClick={() => setStep(8)}
                  onConnectLater={connectLater}
                  transactionExpanded={transactionExpanded}
                  uploadComplete={uploadComplete}
                  onUploadButtonClick={() => setStep(11)}
                  onDataClick={() => setStep(12)}
                  onGoCardlessClick={() => setStep(13)}
                />
              </div>
            </div>
            <div className="mt-5 lg:mb-20">
              {shouldRenderSubmitApplication() && (
                <button
                  className="w-52"
                  disabled={!isDone}
                  onClick={() => setShowSubmitModal(true)}
                >
                  {resultApplication?.status === "expired"
                    ? "Update application"
                    : "Submit application"}
                </button>
              )}
            </div>
          </div>
          <OnboardInfo />
        </div>
        <WelcomeModal
          showModal={showWelcomeModal}
          setShowModal={setShowWelcomeModal}
        />
        <SubmitModal
          visible={showSubmitModal}
          hide={() => setShowSubmitModal(false)}
        />
        <AccountingModals
          hide={hide}
          step={step}
          setStep={setStep}
          files={files}
          setFile={setFile}
          uploadedFilesLength={uploadedFilesLength}
          onHandleRemove={onHandleRemove}
          text={text}
          setText={(e) => setText(e.target.value)}
          uploadFilesAndComment={uploadFilesAndComment(true)}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
          loading={loading}
        />
        <SubscriptionModals
          hide={hide}
          step={step}
          setStep={setStep}
          files={files}
          setFile={setFile}
          uploadedFilesLength={uploadedFilesLength}
          onHandleRemove={onHandleRemove}
          text={text}
          setText={(e) => setText(e.target.value)}
          uploadFilesAndComment={uploadFilesAndComment(false)}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
          loading={loading}
          subscriptionCallback={SubscriptionCallback}
        />
        <BankModals
          edit={edit}
          hide={hide}
          step={step}
          setStep={setStep}
          callback={SyncCallback}
          files={files}
          setFile={setFile}
          uploadedFilesLength={uploadedFilesLength}
          onHandleRemove={onHandleRemove}
          text={text}
          setText={(e) => setText(e.target.value)}
          uploadFilesAndComment={uploadFilesAndComment(false)}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
          loading={loading}
          goCardlessReference={gocardless_reference}
          goCardlessError={gocardless_error}
        />
        <SelectCompanyModal
          me={me}
          hide={() => setShowCompanyModal(false)}
          visible={isCompanyModalVisible}
          title="Select Company"
        />
      </div>
    </>
  );
};

export default withRouter(Onboard);
