import { Icon } from "@iconify/react";
import Line from "../line";
import { formatDate } from "../../common/utils";

export const Item = ({
  repaid_amount,
  repaid_percentage,
  credit_offer,
  credit_amount,
  index,
  country,
  activation_date,
  interest,
  agreement,
  loan_reference,
  documents,
  setDocuments
}) => {
  const formatter = new Intl.NumberFormat(country);
  const loanAmount = `${formatter.format(credit_amount)} ${
    credit_offer.currency
  }`;

  const date = formatDate(activation_date);
  const getInterestRate = () => {
    if (interest.toString().length > 2) {
      return interest.toFixed(2);
    }
    return interest;
  };
  const getLastTwoCharacters = (str) => {
    if (str) {
      return str.slice(-2);
    }
    return "";
  };

  return (
    <tr className="text-black border-b-gray-100 border-b-2">
      <td className="px-2">
          <p className="text-sm md:text-base">{`#${getLastTwoCharacters(
          loan_reference
        )}`}</p>
      </td>
      <td className="px-2">
        <p className="text-sm md:text-base">{loanAmount}</p>
      </td>
      <td className="px-2">
        <p className="text-sm md:text-base mx-auto">{date}</p>
      </td>
      <td className="px-2">
        <p className="text-sm md:text-base">{`${getInterestRate()} %`}</p>
      </td>
      <td className="px-2">
        <div className="tooltip static pl-0">
          <span className="text-small">{`${repaid_percentage} %`}</span>
          <Line
            width={55}
            bgColor={repaid_percentage === 100 ? "#DAF5B4" : "#F9DD8E"}
            lineColor={repaid_percentage === 100 ? "#79AA36" : "#F4C400"}
            progress={repaid_percentage / 100}
          />
        </div>
      </td>
      <td className="px-2">
      {documents && documents.length > 0 && (
          <Icon icon="mi:document-check" width={24} color="#450B70" className="mx-auto cursor-pointer" onClick={() => setDocuments(documents)}/>
        )}
      </td>
    </tr>
  );
};
