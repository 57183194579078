import { Icon } from "@iconify/react"

export const SmileyButtons = ({ onClick, currentSmiley = null }) => {
  const smileyList = [
    {
      id: 0,
      icon: 'tabler:mood-sad-2'
    },
    {
      id: 1,
      icon: 'tabler:mood-sad'
    },
    {
      id: 2,
      icon: 'tabler:mood-empty'
    },
    {
      id: 3,
      icon: 'tabler:mood-smile'
    },
    {
      id: 4,
      icon: 'tabler:mood-happy'
    }
  ]

  return (
    <div className="flex flex-row gap-3">
      {
        smileyList.map(({ icon, id }) => {
        const isActive = currentSmiley === id;
         return (
          <div
            key={icon} 
            onClick={() => onClick(id)}
            className={`${isActive && 'bg-float-purple-5'} border-float-grey-10 border rounded-full w-fit p-1.5 cursor-pointer`}>
            <Icon 
              icon={icon}
              width={25}
              className="text-float-purple-2"
            />
            </div>
         )
        })
      }
    </div>
  )
}