import { Icon } from "@iconify/react";
import { InfoModal } from "../infoModal";
import { useGetFormatBytes } from "../../utils";

export const LoanDocumentModal = ({ hide, visible, documents }) => {
  const { formatBytes } = useGetFormatBytes();
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Loan documents"
      closeText="Close"
      content={
        <div className="w-96">
          <ul>
            {documents.map((file, index) => (
              <li
                key={`${file.name}+${index}`}
                className="grid grid-cols-[40px_minmax(160px,_1fr)_40px] gap-3 border-b border-float-grey-10 last:border-none py-3"
              >
                <div className="bg-float-purple-6 px-2 rounded-md h-10 flex flex-wrap content-center">
                  <Icon icon="mi:document-check" width={24} color="#450B70" />
                </div>
                <div className="grow">
                  <p
                    className="cursor-pointer text-float-purple-2 font-medium text-sm leading-6 truncate"
                    onClick={() => window.open(file.document)}
                  >
                    {file.file_name.split("/").pop()}
                  </p>
                  <div className="flex">
                    <p className="text-xs font-normal float-black-90 leading-5 flex-col">
                      {formatBytes(file.file_size)}
                    </p>
                    {file.created_date && (
                      <Icon
                        icon="formkit:date"
                        width={12}
                        className="my-auto ml-2"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <Icon
                    icon="mingcute:download-3-line"
                    width={24}
                    color="#450B70"
                    className="cursor-pointer"
                    onClick={() => window.open(file.document)}
                  />
                </div>
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};
