import { Icon } from "@iconify/react";

const CreditRating = ({ rating }) => {
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(
          <Icon
            key={i}
            icon="ic:baseline-star-rate"
            className="text-float-purple-3"
            width="20"
          />
        );
      } else if (i === fullStars && hasHalfStar) {
        stars.push(
          <Icon
            key={i}
            icon="ic:baseline-star-half"
            className="text-float-purple-3"
            width="20"
          />
        );
      } else {
        stars.push(
          <Icon
            key={i}
            icon="ic:outline-star-rate"
            className="text-float-purple-3"
            width="20"
          />
        );
      }
    }
    return stars;
  };
  return <div className="flex flex-row">{renderStars(rating)}</div>;
};

export default CreditRating;
