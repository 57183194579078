import { InfoModal, TextArea } from "../../../../../../components";
import { useGetRadioButtons, useFeedbackForm } from "../../../hooks";
import { SmileyButtons } from "../smileyButtons";

export const FeedbackModal = ({
  hide,
  visible,
  currentSmiley,
  setCurrentSmiley,
  onSubmitSuccess,
}) => {
  const { radioButtons } = useGetRadioButtons();
  const { formik } = useFeedbackForm({ currentSmiley, hide, onSubmitSuccess });

  return (
    <InfoModal
      visible={visible}
      hide={hide}
      title="Help us get even better"
      submitButton={
        <button
          type="submit"
          form="connectFeedback"
          onClick={formik.handleSubmit}
        >
          Send
        </button>
      }
      closeText="Close"
      content={
        <form
          id="connectFeedback"
          className="sm:w-[512px] overflow-auto max-h-[500px] "
        >
          <div className="flex flex-col gap-5 text-float-grey-90">
            <p className="text-sm">How was your experience so far?</p>
            <SmileyButtons
              onClick={setCurrentSmiley}
              currentSmiley={currentSmiley}
            />
            <TextArea
              id="text"
              label="Your feedback"
              placeholder="Write your feedback here ..."
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.errors.text}
            />
            <p className="text-sm">How did you first hear about us?</p>
            {formik.errors.discovered_by && (
              <p className="text-xs text-functional-red-100 -mt-4">
                {formik.errors.discovered_by}
              </p>
            )}
            <div className="flex flex-col gap-4" role="group" id="radioGroup">
              {radioButtons.map(({ label, value }) => (
                <label key={label} className="flex items-center">
                  <input
                    required
                    className="mr-2 radiobutton"
                    type="radio"
                    name="discovered_by"
                    value={value}
                    onChange={formik.handleChange}
                    checked={formik.values.discovered_by === value}
                  />
                  {label}
                </label>
              ))}
            </div>
            {formik.values.discovered_by === "other" && (
              <TextArea
                id="other"
                required
                label="Please specify"
                placeholder="Write how you found out about us ..."
                value={formik.values.other}
                onChange={formik.handleChange}
                error={formik.errors.other}
              />
            )}
          </div>
        </form>
      }
    />
  );
};
