import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { HelpModal } from "../helpModal";
import { NDAModal } from "../ndaModal";
import { getNDA } from "../../../../../api/nda";

const Container = ({ title, text, bottomText, buttonText, onClick, icon }) => {
  return (
    <div className="flex flex-row gap-4">
      {icon}
      <div className="flex flex-col gap-1">
        <h4 className="font-bold">{title}</h4>
        <p className="text-sm">{text}</p>
        {bottomText && <p className="text-sm underline">{bottomText}</p>}
        {buttonText && (
          <p
            className="text-float-purple-2 cursor-pointer text-sm"
            onClick={onClick}
          >
            {buttonText}
          </p>
        )}
      </div>
    </div>
  );
};

export const OnboardInfo = () => {
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [showNDAModal, setShowNDAModal] = useState(false);
  const [ndaRequested, setNDARequested] = useState(null);

  useEffect(() => {
    const fetchNDA = async () => {
      const response = await getNDA();
      if (response?.length > 0) {
        setNDARequested(response[0]);
      }
    };
    fetchNDA();
  }, []);

  const ndaStatus = {
    rejected: "NDA request has been declined.",
    pending: "NDA request is in progress.",
    accepted: "NDA has been successfully signed.",
  };

  return (
    <div className="grid lg:grid-rows-[minmax(80px,100px)_minmax(100px,1fr)] gap-7 mb-7">
      <div />
      <div className="flex flex-col gap-6 max-w-xl">
        <Container
          title={"We keep your data secure and private"}
          text={
            "All information you share is secured by encryption and protected by our strict non-disclosure policy. We will never share your data with unauthorized third parties."
          }
          bottomText={ndaRequested ? ndaStatus[ndaRequested.status] : null}
          buttonText={ndaRequested ? null : "Request NDA"}
          onClick={() => setShowNDAModal(true)}
          icon={
            <div className="bg-float-purple-7.5 p-2 rounded-full h-fit">
              <Icon
                icon={"ph:lock-bold"}
                width={24}
                className={"text-float-purple-2"}
              />
            </div>
          }
        />
        <Container
          title={"Need any help?"}
          text={"We’re here for you if you have any questions."}
          buttonText={"Book a call with us"}
          onClick={() => setShowHelpModal(true)}
          icon={
            <div className="h-fit">
              <img
                className="object-cover rounded-tl rounded-tr-2xl rounded-br rounded-bl-2xl"
                src="customerService.png"
                alt="Customer service"
              />
            </div>
          }
        />
      </div>
      <HelpModal hide={() => setShowHelpModal(false)} visible={showHelpModal} />
      <NDAModal
        hide={() => setShowNDAModal(false)}
        visible={showNDAModal}
        setNDARequested={setNDARequested}
      />
    </div>
  );
};
