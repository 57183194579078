import { useSignUpBankID } from "../../automated/hooks";
import BankIDButton from "../../../../components/bankIDButton";

export const SignUpBankID = ({ country }) => {
  const { formik } = useSignUpBankID({ country });
  const getButtonText = () => {
    switch (country?.code) {
      case "dk":
        return "Continue with NemID";
      default:
        return "Continue with BankID";
    }
  };

  return (
    <form className="w-full" onSubmit={formik.handleSubmit}>
      <div className="w-full flex flex-col items-center gap-2 mb-7">
        <BankIDButton
          text={getButtonText()}
          className="w-full mt-8"
          country={country}
        />
        <p className="text-float-grey-90 text-sm">
          No commitments, no personal credit check
        </p>
      </div>
    </form>
  );
};
