import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../hooks/useWhoAmI";
import { Toast } from "../toast";
import { ToastType } from "../../types";
import {
  getAspsp,
  postRequisition,
  getRequisitions,
  getRequisition,
  postAISSync,
  getAccount,
} from "../../api";
import { InfoModal } from "..";
import Loading from "../loading";
import { BankList } from "./components/bankList";
import { CountryList } from "./components/countryList";

export const BankSyncGoCardlessModal = ({
  hide,
  visible,
  goCardlessReference,
  goCardlessError,
  edit,
}) => {
  const { me, updateWhoAmI } = useContext(UserContext);
  const history = useHistory();
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState({
    code: me.selected_company.country.code,
    name: me.selected_company.country.name,
  });
  const [done, setDone] = useState(false);
  const handleBanks = async (country_code) => {
    const aspsp = await getAspsp(country_code, "gocardless");
    setBanks(aspsp);
    setLoading(false);
  };
  const [reference, setReference] = useState(goCardlessReference);

  useEffect(() => {
    if (visible && !reference && me) {
      handleBanks(country.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, reference, setBanks, me]);

  useEffect(() => {
    const handleReference = async () => {
      try {
        setLoading(true);
        const result = await getRequisitions();
        const requisition = result.find(
          (r) => r.reference === goCardlessReference
        );
        if (requisition) {
          const requisitionResult = await getRequisition(
            requisition.requisition_id
          );
          if (
            !requisitionResult.accounts ||
            requisitionResult.accounts.length === 0
          ) {
            Toast({
              title: "No accounts found",
              text: "Unable to connect bank - no accounts were found",
              type: ToastType.ERROR,
            });
            setReference(null);
            handleBanks(country.code);
            setLoading(false);
            return;
          }
          await postAISSync({
            requisition_id: requisitionResult.id,
            accounts: requisitionResult.accounts,
          });
          Toast({
            type: ToastType.SUCCESS,
            title: "Yes, success",
            text: "Successfully connected bank",
          });
          const timer = setInterval(async () => {
            const account = await getAccount();
            if (account.find((a) => a.provider === "GO_CARDLESS")) {
              setDone(true);
              clearInterval(timer);
            }
          }, 1000);
        } else {
          Toast({
            title: "Something went wrong",
            text: "Requisition not found",
            type: ToastType.ERROR,
          });
        }
      } catch {
        Toast({
          text: "Something went wrong",
          type: ToastType.ERROR,
        });
      }
    };
    if (reference && !goCardlessError) {
      handleReference();
    }
    if (goCardlessError) {
      Toast({
        title: "Cancelled",
        text: `Bank connection error. ${goCardlessError}`,
        type: ToastType.WARNING,
      });
      setReference(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reference, goCardlessError]);

  useEffect(() => {
    if (country && visible && !reference) {
      setLoading(true);
      handleBanks(country.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, goCardlessReference, visible]);

  useEffect(() => {
    if (done) {
      updateWhoAmI();
      history.push(edit ? "/connect" : "/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [done]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const result = await postRequisition({
        institution_id: selectedBank,
        redirect_to: edit ? "connect" : "dashboard",
      });
      if (result.status === "CR") {
        window.location.href = result.link;
      } else {
        Toast({
          title: "Something went wrong",
          message: `Status ${result.status}`,
          type: ToastType.ERROR,
        });
      }
    } catch (ex) {
      console.log("error", ex);
      setLoading(false);
      Toast({
        message: "Something went wrong",
        type: ToastType.ERROR,
      });
    }
  };

  useEffect(() => {
    if (selectedBank) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBank]);
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Select your bank"
      closeText="Cancel"
      content={
        <div className="max-w-[500px] md:w-[500px]">
          {loading ? (
            <div className="flex flex-col items-center justify-center space-y-4 py-8">
              <div className="relative">
                <Loading />
              </div>
              {goCardlessReference && (
                <div className="space-y-2 text-center">
                  <p className="font-medium">Establishing secure connection</p>
                  <p className="text-sm text-muted-foreground">
                    We&apos;re connecting to your bank. This usually takes less
                    than a minute.
                  </p>
                </div>
              )}
            </div>
          ) : country ? (
            <BankList
              country={country}
              banks={banks}
              setSelectedBank={setSelectedBank}
              selectedBank={selectedBank}
              setCountry={setCountry}
            />
          ) : (
            <CountryList setCountry={setCountry} country={country} />
          )}
        </div>
      }
    />
  );
};
