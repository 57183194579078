import { useContext } from "react";
import { useHistory } from "react-router";
import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";
import DoughnutChart from "../doughnutChart";

const LoanOverview = () => {
  const history = useHistory();
  const { me, loading: user_loading } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: "v1/credit_offer/summary/",
  });

  const [resultApplication, loadingApplication, errorApplication] = useAPI({
    me,
    path: "v1/application/",
  });

  if (loading || user_loading || loadingApplication) {
    return <Loading />;
  }

  if (error || errorApplication) {
    return <p>An error occurred, please try again later.</p>;
  }

  let formatterShort = new Intl.NumberFormat(me?.selected_company.country, {
    notation: "compact",
    minimumFractionDigits: 1,
    maximumFractionDigits: 4,
    minimumSignificantDigits: 1,
    maximumSignificantDigits: 4,
  });
  const total = `${formatterShort.format(result.total_credit)} ${
    result.currency
  }`;

  const currentLoans = (result.used_credit / 1000).toFixed(0);
  const availableCredit = (result.available_credit / 1000).toFixed(0);
  const expiredCredit = (result.expired_credit / 1000).toFixed(0);

  const getButtonText = () => {
    if (resultApplication?.status === "active") {
      return "Get funding";
    }
    if (resultApplication?.status === "expired") {
      return "Renew Credit Offer";
    }

    if (resultApplication?.status === "pending") {
      return "Pending renewal";
    }
  };
  return (
    <div className="flex flex-col md:flex-row items-center w-full gap-2 p-4">
      <div className="mb-2">
        <DoughnutChart
          currentLoans={currentLoans}
          availableCredit={availableCredit}
          expiredCredit={expiredCredit}
          has_any_valid_credit_offer={result.has_any_valid_credit_offer}
          total={total}
          centerText={2.1}
          position="top"
        />
      </div>
      <div className="md:w-5/6">
        <div className="flex flex-row items-center gap-1">
          <span className="bg-float-purple-2 w-3 h-3 md:w-4 md:h-4 rounded-full"></span>
          <p className="text-xs md:text-sm w-5/6">{`Currently outstanding loans ${currentLoans}k`}</p>
        </div>
        {result.has_any_valid_credit_offer ? (
          <div className="flex flex-row items-center gap-1">
            <span className="bg-float-orange-3 w-3 h-3 md:w-4 md:h-4 rounded-full"></span>
            <p className="text-xs md:text-sm w-5/6">{`Available credit ${availableCredit}k`}</p>
          </div>
        ) : (
          <div className="flex flex-row items-center gap-1">
            <span className="bg-float-grey-50 w-3 h-3 md:w-4 md:h-4 rounded-full"></span>
            <p className="text-xs md:text-sm w-5/6">{`Expired credit ${expiredCredit}k`}</p>
          </div>
        )}

        <button
          className="bg-float-purple-2 border-float-purple-2 w-full mt-4 mb-4 md:mt-16 max-w-[200px]"
          onClick={() => {
            if (resultApplication?.status === "expired") {
              history.push("/connect");
            } else {
              history.push("/loan");
            }
          }}
          disabled={resultApplication?.status === "pending"}
        >
          {getButtonText()}
        </button>
      </div>
    </div>
  );
};

export default LoanOverview;
