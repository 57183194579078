import Input from "../../../../../components/input";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../../../common/constants";
import { useYourDetailsForm } from "./hooks/useYourDetailsForm/useYourDetailsForm";
import { Button } from "../../../../../components";
import { useState } from "react";

export const YourDetails = ({ nextPage, email, isActiveUser }) => {
  const [captcha, setCaptcha] = useState(null);
  const { formik, loading, submitError } = useYourDetailsForm({
    nextPage,
    email,
    captcha,
    isActiveUser,
  });

  return (
    <div className="flex flex-col gap-2 mt-24 mx-auto w-[560px] px-6">
      <h2 className="text-float-grey-100">Your details</h2>
      <div>
        <p className="text-float-grey-70 text-base italic">
          All fields are required.
        </p>
      </div>
      <form className="flex flex-col gap-3" onSubmit={formik.handleSubmit}>
        <Input
          type="text"
          name="first_name"
          id="first_name"
          label="First name"
          placeholder="Enter first name"
          value={formik.values.first_name}
          onChange={formik.handleChange}
          error={formik.errors.first_name}
        />
        <Input
          type="text"
          name="last_name"
          id="last_name"
          label="Last name"
          placeholder="Enter last name"
          value={formik.values.last_name}
          onChange={formik.handleChange}
          error={formik.errors.last_name}
        />
        <Input
          type="text"
          name="phone_number"
          id="phone_number"
          label="Phone number"
          placeholder="Enter phone number"
          value={formik.values.phone_number}
          onChange={formik.handleChange}
          error={formik.errors.phone_number}
        />
        <Input
          type="text"
          label="Job title"
          id="job_title"
          value={formik.values.job_title}
          onChange={formik.handleChange}
          error={formik.errors.job_title}
          placeholder="Enter job title"
        />
        {!isActiveUser && (
          <>
            <span className="w-3/4 mx-auto px-18 h-px bg-float-grey-10 my-3" />

            <Input
              type="password"
              name="password1"
              id="password1"
              label="Password"
              placeholder={isActiveUser ? "-----" : "Enter password"}
              disabled={isActiveUser}
              value={formik.values.password1}
              onChange={formik.handleChange}
              error={formik.errors.password1 || submitError.password1}
            />
            <Input
              type="password"
              name="password2"
              id="password2"
              label="Confirm password"
              placeholder={isActiveUser ? "-----" : "Confirm password"}
              disabled={isActiveUser}
              value={formik.values.password2}
              onChange={formik.handleChange}
              error={formik.errors.password2 || submitError.password2}
            />

            <ReCAPTCHA
              className="mt-6"
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={(value) => {
                setCaptcha(value);
              }}
            />
          </>
        )}
        {submitError.other && (
          <p className="text-functional-red-100 text-sm">{submitError.other}</p>
        )}
        {submitError.email && (
          <p className="text-functional-red-100 text-sm">{submitError.email}</p>
        )}
        <Button className="mt-8" type="submit" loading={loading}>
          Continue
        </Button>
      </form>
    </div>
  );
};
