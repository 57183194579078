import { useEffect, useState, useContext } from "react";
import Loading from "../loading";
import { doGet } from "../../common/axiosUtil";
import { BankSync } from "../../components/bankSync";
import { CountryContext } from "../../hooks/useCountry";

export const AccountSelector = ({ onComplete }) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(null);
  const { country } = useContext(CountryContext);

  const fetchResult = async () => {
    const accounts = await doGet("v1/ais/accounts/?verified_status=verified");
    setResult(accounts);
    setLoading(false);
  };

  useEffect(() => {
    fetchResult();
  }, []);

  const callback = () => {
    setLoading(true);
    fetchResult();
  };

  if (loading) {
    return <Loading />;
  }

  if (result.length === 0) {
    return (
      <>
        <p>
          You dont have any bank account synced with Float, we need to sync
          atleast one to be able to payout the loan.
        </p>
        <p>Please click below to start the sync.</p>
        {country.ais_kosma && <BankSync callback={callback} />}
      </>
    );
  }
  return (
    <div className="flex flex-col">
      <p className="text-center">
        Please choose which bank and account you want the loan to be payed out
        to.
      </p>
      <div className="flex flex-col w-full my-4">
        {result?.map((r) => {
          // const bank = banks.find((b) => b.bicfi === r.bicfi);
          return (
            <div className="flex flex-row w-full" key={r.id}>
              <label htmlFor={r.id} className="pl-8 mr-auto">
                {/* {bank?.name && (
                  <img
                    className="inline-block pr-4"
                    src={bank?.logo_url}
                    width="50"
                    alt={`Logo for ${bank?.name}`}
                  />
                )} */}
                {r.alias ? <span>{r.alias}</span> : "Account"}
                {r.account_number && <span> {r.account_number}</span>}
                {r.iban && <span> / {r.iban}</span>}
                {r.currency && <span> ({r.currency})</span>}
              </label>
              <input
                className="my-auto ml-auto mr-8"
                type="radio"
                id={r.id}
                name="bank"
                value={r.id}
                onClick={(e) => setSelected(r)}
              />
            </div>
          );
        })}
      </div>
      <button
        className="ml-auto my-8"
        disabled={selected === null}
        onClick={() => onComplete(selected)}
      >
        Next
      </button>
    </div>
  );
};
