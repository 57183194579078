import { PendingItem } from "./pendingItem";

const PendingLoans = ({ loans, me, error, refresh }) => {
  if (error) {
    return <p>An error occurred, please try again later.</p>;
  }

  if (loans.length === 0) {
    return (
      <p className="flex flex-col p-2 text-float-grey-1">
        You don't have any pending loans.
      </p>
    );
  }
  return (
    <>
      {/* <h4 className="ml-2">Pending loans</h4> */}
      <div className="w-full h-full overflow-auto">
        <table className="w-full mb-4">
          <tr className="text-float-grey-1 sticky top-0 bg-white">
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Amount
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Activation date
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Requested date
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base">
              Interest
            </th>
            <th className="font-bold p-2 text-left text-sm md:text-base"></th>
          </tr>
          {loans.map((i, index) => (
            <PendingItem
              key={index}
              index={index}
              country={me.selected_company.country || "en"}
              refresh={refresh}
              {...i}
            />
          ))}
        </table>
      </div>
    </>
  );
};

export default PendingLoans;
