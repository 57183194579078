import { useContext, useState } from "react";
import {
  CreatedLogo,
  CreditOfferLogo,
  DataPrivacyLogo,
  AddUserLogo,
} from "./icons";
import { ProgressBar } from "./progressBar";
import { UserContext } from "../../hooks/useWhoAmI";
import { patchUser } from "../../api";

export const WelcomeModal = ({ showModal, setShowModal }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const { me, updateWhoAmI } = useContext(UserContext);
  const closeModal = async () => {
    /* Do PATCH here on user */
    try {
      await patchUser({ show_onboarding_guide: false });
      await updateWhoAmI();
    } catch (e) {
      // TODO: error handling here?
      console.log("error", e);
    }
    setShowModal(false);
  };

  const goNext = () => setCurrentStep(currentStep + 1);
  const goBack = () => setCurrentStep(currentStep - 1);

  const steps = [
    {
      step: 0,
      title: "Your account has been created",
      text: (
        <p className="text-base font-normal">
          {`Welcome ${me.first_name}, we are happy to have you onboard. You can now log out and return to this page at any time.`}{" "}
        </p>
      ),
      icon: <CreatedLogo />,
    },
    {
      step: 1,
      title: "Connect and get offer in a couple of days",
      text: (
        <p className="text-base font-normal">
          By simply connecting your accounting system, your subscription
          management information, and your bank account, we can conduct our
          credit analysis quickly and accurately, within a couple of days.
        </p>
      ),
      icon: <CreditOfferLogo />,
    },
    {
      step: 2,
      title: "Data privacy and security",
      text: (
        <p className="text-base font-normal">
          All data and information that you share with us is securely stored on
          servers in the EU. The data will only be used for assessing your
          credit rating. We have a strict non-disclosure policy in place, and
          you can generate your personal NDA if desired.
        </p>
      ),
      icon: <DataPrivacyLogo />,
    },
    {
      step: 3,
      title: "Add more users",
      text: (
        <p className="text-base font-normal">
          If necessary, you can add additional users to assist with connecting
          your financials and setting up loans. This may be helpful if you want
          someone within or outside the organisation to handle certain tasks.
        </p>
      ),
      icon: <AddUserLogo />,
    },
  ];

  return (
    <div
      id="modal"
      aria-hidden="true"
      className={`${
        showModal ? "visible" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-screen md:inset-0 h-screen md:h-full flex items-center justify-center bg-black bg-opacity-50`}
    >
      <div className="relative p-4 h-full md:h-auto sm:w-3/4 lg:w-2/4 max-w-4xl	">
        <div className="relative bg-white rounded-xl box-shadow sm:h-[580px]">
          <div className="flex justify-between items-start p-5 relative rounded-t-lg bg-gradient-to-r from-float-purple-gradient-light-1 to-float-purple-gradient-light-2">
            <div className="mx-auto my-16 animate-in fade-in duration-200">
              {steps[currentStep].icon}
            </div>
            <div
              className="text-gray-400 bg-transparent rounded-lg text-sm items-center cursor-pointer absolute right-5"
              onClick={closeModal}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <div className="p-6 min-h-[300px] grid grid-rows-[10px_1fr_50px]">
            <ProgressBar currentStep={currentStep} steps={steps} />
            <div className="flex gap-3 flex-col my-7 transition-all">
              <h3 className="font-bold">{steps[currentStep].title}</h3>
              {steps[currentStep].text}
            </div>
            <div className="flex gap-3 h-fit">
              {currentStep === 0 && (
                <button className="bg-float-purple-2 w-full" onClick={goNext}>
                  Next
                </button>
              )}
              {currentStep > 0 && currentStep < steps.length - 1 && (
                <>
                  <button
                    className="bg-white text-float-purple-2 w-full"
                    onClick={goBack}
                  >
                    Back
                  </button>
                  <button className="bg-float-purple-2 w-full" onClick={goNext}>
                    Next
                  </button>
                </>
              )}
              {currentStep === steps.length - 1 && (
                <>
                  <button
                    className="bg-white text-float-purple-2 w-full"
                    onClick={goBack}
                  >
                    Back
                  </button>
                  <button
                    className="bg-float-purple-2 w-full"
                    onClick={closeModal}
                  >
                    Get started
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
