import { useFormik } from "formik";
import { useContext, useState } from "react";
import { patchUser } from "../../../api";
import { Toast } from "../../../components";
import { UserContext } from "../../../hooks/useWhoAmI";
import { ToastType } from "../../../types";
import { validationSchema } from "./validation";

export const useYourDetailsForm = () => {
  const { me, updateWhoAmI } = useContext(UserContext);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    try {
      setError({});
      setIsLoading(true);
      await patchUser(data);
      await updateWhoAmI();
      Toast({
        type: ToastType.SUCCESS,
        title: "Your profile was saved",
      });
    } catch (e) {
      setError(e?.response?.data);
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: "Couldn't update your profile",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      first_name: me?.first_name || "",
      last_name: me?.last_name || "",
      email: me?.email || "",
      phone_number: me?.phone_number || "",
      job_title: me?.job_title || "",
    },
    onSubmit,
    validationSchema,
  });

  return {
    formik,
    isLoading,
    error,
  };
};
