export const BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_ENVIRONMENT = process.env.REACT_APP_SERVER_ENV;
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const MONTO_TENANT_UUID = process.env.REACT_APP_MONTO_TENANT_UUID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const COMPANY_LOOKUP = {
  se: {
    placeholder: "XXXXXXXXXX",
    regex: new RegExp(/^\d{10}$/),
  },
  fi: {
    placeholder: "XXXXXXX-X",
    regex: new RegExp(/^\d{7}[-|(\s)]{1}\d{1}$/),
  },
  no: {
    placeholder: "XXXXXXXXX",
    regex: new RegExp(/^\d{9}$/),
  },
  dk: {
    placeholder: "XXXXXXXX",
    regex: new RegExp(/^\d{8}$/),
  },
  other: {
    placeholder: "XXXX",
    regex: new RegExp(/^.{4,50}$/),
  },
};

export const CURRENCIES = {
  se: "SEK",
  fi: "EUR",
  no: "NKK",
  dk: "DKK",
};
