import Accordion from "../accordion";
import { BankSync } from "../bankSync";
import { CountryContext } from "../../hooks/useCountry";
import { useContext } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../loading";
import { Icon } from "@iconify/react";
import { FileList } from "../fileList";
import { BankConnectionList } from "../bankConnectList";
import { useGetBankConnections } from "../../views/dashboard/onboard/hooks";
import { UploadButton } from "../uploadButton";
import { useTransactionFiles } from "../../hooks/useTransactionsFiles";
import { DeleteFileModal } from "../../views/dashboard/onboard/components";
import { useDeleteFileModal } from "../../views/dashboard/onboard/hooks";
import { ConnectButton } from "../connectButton";

export const TransactionAccordion = ({
  checked,
  connectLater,
  bankCallBack,
  onInfoClick,
  onConnectLater,
  open,
  onUploadButtonClick,
  uploadComplete,
  onDataClick,
  onGoCardlessClick,
}) => {
  const { me } = useContext(UserContext);
  const { country } = useContext(CountryContext);
  const connectLaterIcon = connectLater && "mdi:clock-plus-outline";
  const { bankConnections } = useGetBankConnections();
  const { transactionFiles, deleteTransactionFileById, isLoading } =
    useTransactionFiles({ uploadComplete });
  const {
    showDeleteModal,
    openDeleteModal,
    closeDeleteModal,
    onDeleteFile,
    currentFile,
  } = useDeleteFileModal({ deleteFileById: deleteTransactionFileById });

  const hasFiles = transactionFiles && transactionFiles.length > 0;

  if (!me) {
    return <Loading />;
  }

  return (
    <>
      <Accordion
        open={open}
        title={"Add bank transactions"}
        checked={checked}
        icon={connectLaterIcon}
      >
        <div className="ml-[77px] mr-12">
          <p className="mb-8 mr-5">
            {country.ais_upload && !country.ais_go_cardless ? (
              <>
                To provide you with the best possible loan options, we require
                your bank transaction data from the last 12 months.
              </>
            ) : (
              <>
                By connecting your bank transactions we get anonymised bank
                transactions from your bank with up to 24 months history. We
                will not be able to make any changes or edit anything. We will
                only be able to view your data.
              </>
            )}
          </p>
          {
            <div className="flex flex-col gap-4 md:flex-row md:gap-0 mb-8">
              <div className="flex flex-col gap-2 md:gap-4 items-center md:pr-6">
                {country.ais_kosma && <BankSync callback={bankCallBack} />}
                {country.ais_go_cardless && (
                  <ConnectButton text="Connect" onClick={onGoCardlessClick} />
                )}
                {country.ais_upload && (
                  <>
                    <UploadButton
                      onClick={onUploadButtonClick}
                      width={country.ais_go_cardless ? "xl:w-64" : "xl:w-48"}
                    />
                    <p
                      className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                      onClick={onDataClick}
                    >
                      <Icon icon="ri:information-line" width={16} />
                      What data do we need?
                    </p>
                  </>
                )}
              </div>
              {country.ais_go_cardless && (
                <div className="flex flex-col gap-2 md:gap-4 items-center md:pl-6 md:border-l-2 border-float-grey-10 ">
                  <button
                    disabled={checked}
                    onClick={onConnectLater}
                    className="bg-white border-float-grey-25 text-float-purple-2 xl:w-48 justify-center"
                  >
                    Connect later
                  </button>
                  <p
                    className="text-sm font-normal flex gap-2 items-center cursor-pointer text-float-purple-2"
                    onClick={onInfoClick}
                  >
                    <Icon icon="ri:information-line" width={16} />
                    What does this mean?
                  </p>
                </div>
              )}
            </div>
          }
        </div>
        <div>
          <BankConnectionList bankConnections={bankConnections} />
          <div className={`${hasFiles ? "border-t" : ""} px-6`}>
            <FileList
              files={transactionFiles}
              onHandleRemove={openDeleteModal}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Accordion>
      <DeleteFileModal
        visible={showDeleteModal}
        hide={closeDeleteModal}
        file={currentFile}
        onDelete={onDeleteFile}
      />
    </>
  );
};
