import { useContext, useState } from "react";
import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import Input from "../input";
import useAPI from "../../hooks/useAPI";
import { UserContext } from "../../hooks/useWhoAmI";
import Loading from "../loading";
import * as Yup from "yup";
import { postAccount, patchAccount } from "../../api";
import { ToastType } from "../../types";
import { Toast } from "../toast";

export const AccountVerification = ({
  accounts,
  currency,
  onSuccess,
  onCancel,
}) => {
  const steps = {
    form: "FORM",
    confirm: "CONFIRM",
  };
  const [step, setStep] = useState(steps.form);
  const { me } = useContext(UserContext);
  const [result, loading, error] = useAPI({
    me,
    path: `v1/ais/bank_information/${currency}/`,
  });
  const account = accounts.find((x) => x.verified_status === "in_progress");

  const handleContinue = () => {
    Toast({
      type: ToastType.SUCCESS,
      title: "Success",
      text: "Bank account verification proccess successfully started",
    });
    onSuccess();
  };

  const formik = useFormik({
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        if (account) {
          await patchAccount(account.id, {
            iban: values.iban,
            bic: values.bic,
          });
        } else {
          await postAccount({
            iban: values.iban,
            bic: values.bic,
            verified_status: "in_progress",
          });
        }
        setStep(steps.confirm);
      } catch (ex) {
        Toast({
          type: ToastType.ERROR,
          title: "Oh no, there was an error",
          text: ex?.response?.data.error,
        });
      }
    },
    initialValues: {
      iban: account?.iban,
      bic: account?.bic,
      confirm: false,
    },
    validationSchema: Yup.object({
      iban: Yup.string().min(14).max(36).required(),
      bic: Yup.string().min(7).max(14).required(),
      confirm: Yup.bool().oneOf([true], "Field must be checked"),
    }),
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <p>
        Failed initiating account verification process, reason: {error?.detail}
      </p>
    );
  }

  if (step === steps.confirm) {
    return (
      <div className="flex flex-col px-6">
        <Icon
          icon="mdi:verified"
          className="text-float-purple-2 mx-auto"
          width={40}
        />
        <p className="mt-4">
          Thank you! Within the next 2-3 business days, we will complete the
          verification of your bank account. You will receive a notification via
          email once the verification is successfully completed. Afterward, you
          will be able to withdraw your funding from Float.
        </p>
        <button type="submit" className="ml-auto" onClick={handleContinue}>
          Continue
        </button>
      </div>
    );
  }
  return (
    <div className="flex flex-col px-6">
      <Icon
        icon="mdi:verified"
        className="text-float-purple-2 mx-auto"
        width={40}
      />
      <p className="mt-4 mb-2">
        Which bank account would you like to receive the funding to? We quickly
        need to verify that account - here is how:
      </p>
      <ol>
        <li className="font-bold">
          1. &nbsp;Provide us with your IBAN & BIC/SWIFT
        </li>
      </ol>
      <form>
        <div className="flex flex-col mt-2">
          <div className="flex-col">
            <Input
              type="text"
              id="iban"
              label="IBAN"
              placeholder="Fill in your IBAN"
              onChange={formik.handleChange}
              value={formik.values.iban}
              error={formik.errors.iban}
              className="mb-4"
            />
            <Input
              type="text"
              id="bic"
              label="BIC/SWIFT"
              placeholder="Fill in your BIC/SWIFT"
              onChange={formik.handleChange}
              value={formik.values.bic}
              error={formik.errors.bic}
            />
          </div>
          <ol className="mt-4">
            <li className="font-bold">
              2. Transfer {currency} 1.00 from the provided bank account to
              Float’s account (non-refundable). See instructions below for the
              transfer details.
            </li>
          </ol>
          <div className="flex-col mt-4">
            <p>Float IBAN</p>
            <div className="flex flex-row mb-4">
              <Input
                type="text"
                value={result.iban}
                disabled={true}
                className="w-full"
              />
              <Icon
                icon="mdi:content-copy"
                className="text-float-purple-2 my-auto ml-4 hover:cursor-pointer"
                width={25}
                onClick={() => {
                  navigator.clipboard.writeText(result.iban);
                  Toast({
                    type: ToastType.SUCCESS,
                    text: "IBAN copied to clipboard!",
                  });
                }}
              />
            </div>
            <p>Float BIC/SWIFT</p>
            <div className="flex flex-row mb-4">
              <Input
                type="text"
                value={result.bic}
                disabled={true}
                className="w-full"
              />
              <Icon
                icon="mdi:content-copy"
                className="text-float-purple-2 my-auto ml-4 hover:cursor-pointer"
                width={25}
                onClick={() => {
                  navigator.clipboard.writeText(result.bic);
                  Toast({
                    type: ToastType.SUCCESS,
                    text: "BIC/SWIfT copied to clipboard!",
                  });
                }}
              />
            </div>
            <p>Message in transaction</p>
            <div className="flex flex-row">
              <Input
                type="text"
                value={`Account verification - ${me.selected_company.name}`}
                disabled={true}
                className="w-full"
              />
              <Icon
                icon="mdi:content-copy"
                className="text-float-purple-2 my-auto ml-4 hover:cursor-pointer"
                width={25}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `Account verification - ${me.selected_company.name}`
                  );
                  Toast({
                    type: ToastType.SUCCESS,
                    text: "Message for transaction copied to clipboard!",
                  });
                }}
              />
            </div>
            <p>Name</p>
            <div className="flex flex-row">
              <Input
                type="text"
                value="Float Finance AB"
                disabled={true}
                className="w-full"
              />
              <Icon
                icon="mdi:content-copy"
                className="text-float-purple-2 my-auto ml-4 hover:cursor-pointer"
                width={25}
                onClick={() => {
                  navigator.clipboard.writeText("Float Finance AB");
                  Toast({
                    type: ToastType.SUCCESS,
                    text: "Name copied to clipboard!",
                  });
                }}
              />
            </div>
            <p>Address</p>
            <div className="flex flex-row">
              <Input
                type="text"
                value="Regeringsgatan 29, 11153 Stockholm, Sweden"
                disabled={true}
                className="w-full"
              />
              <Icon
                icon="mdi:content-copy"
                className="text-float-purple-2 my-auto ml-4 hover:cursor-pointer"
                width={25}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "Regeringsgatan 29, 11153 Stockholm, Sweden"
                  );
                  Toast({
                    type: ToastType.SUCCESS,
                    text: "Address copied to clipboard!",
                  });
                }}
              />
            </div>
            <div className="flex flex-row mt-4">
              <Input
                type="checkbox"
                className="w-full"
                id="confirm"
                onChange={formik.handleChange}
                value={formik.values.confirm}
              />
              <p className="text-sm">
                I hereby confirm that the transfer has been done
              </p>
            </div>
          </div>
          <div className="flex mt-8">
            <button
              className="mr-auto bg-transparent text-float-purple-2 my-auto cursor-pointer border-transparent font-bold hover:border-transparent hover:bg-float-purple-5"
              onClick={onCancel}
            >
              Close
            </button>
            <button
              type="submit"
              className="ml-auto"
              onClick={formik.handleSubmit}
              disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
