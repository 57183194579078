import { useState } from "react";
import { formatDate } from "../../common/utils";
import { InfoModal } from "../infoModal";
import { patchLoan } from "../../api";
import { Toast } from "../toast";
import { ToastType } from "../../types";

const CancelLoanModal = ({ hide, visible, loanId, onSuccess }) => {
  const cancelLoan = async () => {
    try {
      await patchLoan(loanId, "cancelled_by_user");
      Toast({
        type: ToastType.SUCCESS,
        title: "Success",
        text: "Loan request has been cancelled",
      });
      hide();
      onSuccess();
    } catch (ex) {
      Toast({
        type: ToastType.ERROR,
        title: "Oh no, there was an error",
        text: ex.response.data.error,
      });
    }
  };
  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Loan cancellation"
      closeText="No"
      content={
        <div className="max-w-[500px] md:w-[500px]">
          <p className="truncate text-float-grey-100">
            {`Are you sure you want to cancel the loan request?`}
          </p>
        </div>
      }
      submitButton={<button onClick={cancelLoan}>Yes</button>}
    />
  );
};
export const PendingItem = ({
  status,
  credit_offer,
  credit_amount,
  country,
  activation_date,
  interest,
  id,
  created_date,
  refresh,
}) => {
  const [loanCancelVisible, setLoanCancelVisible] = useState(false);

  const formatter = new Intl.NumberFormat(country);
  const loanAmount = `${formatter.format(credit_amount)} ${
    credit_offer.currency
  }`;

  const date = formatDate(activation_date);
  const createAt = formatDate(created_date);
  const getInterestRate = () => {
    if (interest.toString().length > 2) {
      return interest.toFixed(2);
    }
    return interest;
  };

  return (
    <>
      <tr className="text-black border-b-gray-100 border-b-2">
        <td className="px-2">
          <p className="text-sm md:text-base">{loanAmount}</p>
        </td>
        <td className="px-2">
          <p className="text-sm md:text-base">{date}</p>
        </td>
        <td className="px-2">
          <p className="text-sm md:text-base">{createAt}</p>
        </td>
        <td className="px-2">
          <p className="text-sm md:text-base">{`${getInterestRate()} %`}</p>
        </td>
        <td className="px-2">
          <p
            className="cursor-pointer text-float-purple-2"
            onClick={() => {
              setLoanCancelVisible(true);
            }}
          >
            Cancel
          </p>
        </td>
      </tr>
      <CancelLoanModal
        hide={() => setLoanCancelVisible(false)}
        visible={loanCancelVisible}
        loanId={id}
        onSuccess={refresh}
      />
    </>
  );
};
