import { doGet, doPost } from "../common/axiosUtil";

export const postApplication = async () => {
  const post = await doPost("v1/application/");
  return post;
};

export const getApplication = async () => {
  const get = await doGet("v1/application/");
  return get;
};
