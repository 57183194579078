export const useSuccessLogin = () => {
  const setCookiesAndLogin = ({ setShowCompanyModal, me, url, history }) => {
    if (me?.company?.length > 1 && setShowCompanyModal) {
      setShowCompanyModal(true);
    }

    if (url) {
      window.location.href = url;
    } else {
      history.push("/dashboard");
    }
  };

  return {
    setCookiesAndLogin,
  };
};
