import { useFormik } from "formik";
import { useState } from "react";
import { initiateLogin } from "../../../../../api";
import { Toast } from "../../../../../components";
import { ToastType } from "../../../../../types";

export const useSignUpBankID = ({ country }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      country,
    },
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const startSignUp = await initiateLogin(
          values.country.code,
          `/signup/automated/exchange-code`
        );
        if (startSignUp.url) {
          window.location.href = startSignUp.url;
        }
      } catch (e) {
        Toast({
          type: ToastType.ERROR,
          title: "Oh no, error",
          text: `${e?.response?.data || "Something went wrong"}`,
        });
      } finally {
        setLoading(false);
      }
    },
  });

  return {
    formik,
    loading,
  };
};
