import { BankSync } from "../../../../../components/bankSync";
import { SupportedBanksInfo } from "../supportedBanksInfo";
import {
  postCommentTransaction,
  postCompanyTransaction,
} from "../../../../../api";
import {
  InfoModal,
  ManualModal,
  FileUploaderStateless,
  TextArea,
  FileList,
  UploadButton,
  BankSyncGoCardlessModal,
} from "../../../../../components";
import { SubmitButton } from "../submitButton";
import { UploadTransactionInformation } from "../uploadTransactionInformation";

export const BankModals = ({
  edit,
  hide,
  step,
  setStep,
  callback,
  files,
  setFile,
  uploadedFilesLength,
  onHandleRemove,
  text,
  setText,
  fileUploadError,
  setFileUploadError,
  loading,
  uploadFilesAndComment,
  goCardlessReference,
  goCardlessError,
}) => {
  return (
    <>
      <InfoModal
        hide={hide}
        visible={step === 7}
        title="Supported banks"
        closeText="Close"
        content={<SupportedBanksInfo />}
        submitButton={<BankSync callback={callback} />}
      />
      <InfoModal
        hide={hide}
        visible={step === 8}
        title="Connect bank later"
        closeText="Close"
        content={
          <p className="text-base max-w-xl">
            You can connect you bank accounts at a later stage, but at the
            latest before you get your first loan
          </p>
        }
      />
      <InfoModal
        hide={hide}
        visible={step === 9}
        title="Are you sure you want to connect later?"
        closeText="Close"
        content={
          <p className="text-base max-w-xl">
            Your current connections(s) will be removed. You can connect you
            bank accounts at a later stage, but at the latest before you get
            your first loan
          </p>
        }
      />
      <ManualModal
        hide={hide}
        visible={step === 11}
        title="Manual upload of your bank transactions"
        leftContent={
          <div className="flex flex-col gap-3">
            <FileUploaderStateless
              files={files}
              setFile={setFile}
              uploadedFilesLength={uploadedFilesLength}
              onHandleRemove={onHandleRemove}
              error={fileUploadError}
              setFileUploadError={setFileUploadError}
            />
            <FileList files={files} onHandleRemove={onHandleRemove} />
            <div className={`${files.length > 0 && "border-t"} pt-4`}>
              <TextArea
                id="accountingComment"
                value={text}
                onChange={setText}
                label="Additional comments on your uploads"
                placeholder="Write your comments here..."
              />
            </div>
          </div>
        }
        rightContent={
          <UploadTransactionInformation></UploadTransactionInformation>
        }
        submitButton={
          <SubmitButton
            loading={loading}
            files={files}
            onHandleSubmit={(e) =>
              uploadFilesAndComment({
                e,
                fileEndpoint: postCompanyTransaction,
                commentEndpoint: postCommentTransaction,
              })
            }
          />
        }
      />
      <InfoModal
        hide={hide}
        visible={step === 12}
        title="Manual upload of transaction data"
        closeText="Cancel"
        content={
          <div className="max-w-xl">
            <UploadTransactionInformation />
          </div>
        }
        submitButton={<UploadButton onClick={() => setStep(11)} />}
      />
      <BankSyncGoCardlessModal
        edit={edit}
        hide={hide}
        visible={step === 13}
        goCardlessReference={goCardlessReference}
        goCardlessError={goCardlessError}
      />
    </>
  );
};
