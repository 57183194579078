import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";
import ActiveLoans from "./activeLoans";
import PendingLoans from "./pendingLoans";

const List = () => {
  const { me, loading: user_loading } = useContext(UserContext);
  const [tab, setTab] = useState("active");
  const [activeLoans, setActiveLoans] = useState([]);
  const [repaidLoans, setRepaidLoans] = useState([]);

  const [result, loading, error] = useAPI({
    me,
    path: "v1/loan/?status=accepted",
  });

  const [resultPending, loadingPending, errorPending, refresh] = useAPI({
    me,
    path: "v1/loan/?status=pending",
  });

  useEffect(() => {
    setActiveLoans(result.filter((loan) => loan.repaid_percentage !== 100));
    setRepaidLoans(result.filter((loan) => loan.repaid_percentage === 100));
  }, [result]);
  if (loading || user_loading || loadingPending) {
    return <Loading />;
  }

  if (result.length === 0 && resultPending.length === 0) {
    return (
      <p className="flex flex-col p-2 text-float-grey-1">
        You don't have any loans. Apply for a loan above to get started.{" "}
      </p>
    );
  }
  const listCompoenent = {
    active: (
      <ActiveLoans
        loans={activeLoans}
        me={me}
        error={error}
        loanType="active"
      />
    ),
    pending: (
      <PendingLoans
        loans={resultPending}
        me={me}
        error={errorPending}
        refresh={refresh}
      />
    ),
    repaid: (
      <ActiveLoans
        loans={repaidLoans}
        me={me}
        error={error}
        loanType="repaid"
      />
    ),
  };
  return (
    <>
      <div className="border-b w-full mb-4">
        <div className="flex gap-4 ml-2">
          <div
            className={`settings-active pb-2 cursor-pointer ${
              tab === "active" ? "active" : ""
            }`}
            onClick={() => {
              setTab("active");
            }}
          >
            <p className={`${tab === "active" ? "" : "text-float-grey-90"}`}>
              Active
            </p>
          </div>
          <div
            className={`settings-active pb-2 cursor-pointer ${
              tab === "pending" ? "active" : ""
            }`}
            onClick={() => {
              setTab("pending");
            }}
          >
            <p className={`${tab === "pending " ? "" : "text-float-grey-90"}`}>
              Pending
            </p>
          </div>
          <div
            className={`settings-active pb-2 cursor-pointer ${
              tab === "repaid" ? "active" : ""
            }`}
            onClick={() => {
              setTab("repaid");
            }}
          >
            <p className={`${tab === "repaid " ? "" : "text-float-grey-90"}`}>
              Repaid
            </p>
          </div>
        </div>
      </div>
      {listCompoenent[tab]}
    </>
  );
};

export default List;
