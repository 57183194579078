import { useContext } from "react";
import { useHistory } from "react-router";
import { InfoModal } from "../infoModal";

import { UserContext } from "../../hooks/useWhoAmI";
import useAPI from "../../hooks/useAPI";
import Loading from "../loading";

const ExpiredCreditOffer = ({ hide, visible }) => {
  const history = useHistory();
  const { me, loading: user_loading } = useContext(UserContext);
  const [resultApplication, loadingApplication] = useAPI({
    me,
    path: "v1/application/",
  });

  if (loadingApplication || user_loading) {
    return <Loading />;
  }

  return (
    <InfoModal
      hide={hide}
      visible={visible}
      title="Your credit offer has expired"
      closeText="Close"
      submitButton={
        <button
          disabled={resultApplication?.status === "pending"}
          onClick={() => {
            history.push("/connect");
          }}
          className="ml-auto mt-4"
        >
          Renew
        </button>
      }
      content={
        <div className="flex flex-col mx-auto">
          {resultApplication?.status === "pending" ? (
            <p>
              We have received your new credit offer requested and will contact
              you shortly.
            </p>
          ) : (
            <p>
              Your credit offer has expired. But it's easy to renew - just click
              the button below to start the process.
            </p>
          )}
        </div>
      }
    />
  );
};

export default ExpiredCreditOffer;
